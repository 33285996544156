import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { Link, useNavigate, useParams, useLocation} from "react-router-dom";
import { Breadcrumb, Col, Flex, Form, Input, Row, Select, Radio } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { MainButton } from "../../components/MainButton/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import {
  clearData,
  updateAccountType,
  getPendingCount,
  getGrouping
} from "../AccountTypes/accountTypesSlice";
import { groupingOptions, storeDistroOptions, exportDomesticOptions, sourceOptions } from "../../constants/accountTypes";

import css from "./EditAccountType.module.css";

export const EditAccountType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = useForm();
  
  const { id: accountTypeId } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const onSubmit = async (values) => {
    await dispatch(updateAccountType({ data: { ...values } }));
    await dispatch(getPendingCount());

    form.resetFields();
    navigate("/account-types", { state: { from: "/accountTypeEdited" } });
  };
  
  useEffect(() => {
    dispatch(getGrouping()); 
  }, []);

  useEffect(() => {
    if ( location.state && location.state.record) {
      setInitialValues(location.state.record);
    } 
  }, [location?.state]);
  
  const { grouping, getGroupingInProgress } =
    useSelector((state) => state?.accountTypes) || {};


  const rules = [
    {
      required: true,
      message: "This field is required",
    },
  ];

  if (!initialValues) return null;

  return (<>
      <Breadcrumbs paths={[
        {path: `/account-types/`, breadcrumbName: `Account Types`}, 
        {path: `/account-types/edit`, breadcrumbName: `Edit Account Type`}
      ]} />
      <PageHeader title="Edit Account Type" />

    <Flex className={css.root} vertical>
      <div className={"ibox-holder"}>
        <Form
          form={form}
          name="accountTypeForm"
          style={{ marginTop: 10, marginBottom: 0 }}
          initialValues={initialValues}
          autoComplete="off"
          layout="vertical"
          onFinish={onSubmit}
        >
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="Account"
                className={css.formItem}
                label={<span className={css.label}>Account</span>}
                rules={rules}
              >
                <Input
                  size="large"
                  disabled={true}
                  placeholder="Account"
                  style={{ color: "#737373" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/*
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="Who"
                className={css.formItem}
                label={<span className={css.label}>Who</span>}
                rules={rules}
              >
                <Input
                  size="large"
                  placeholder="Who"
                  style={{ color: "#737373" }}
                />
              </Form.Item>
            </Col>
          </Row>
          */}
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="Store_Distro"
                className={css.formItem}
                label={<span className={css.label}>Store Distro</span>}
                rules={rules}
              >
                <Select
                  allowClear
                  placeholder="Store Distro"
                  align="left"
                  size="large"
                  options={storeDistroOptions}
                />
              </Form.Item>
            </Col>
          </Row>        
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="Country"
                className={css.formItem}
                label={<span className={css.label}>Country</span>}
                rules={rules}
              >
                <Input
                  size="large"
                  placeholder="Country"
                  style={{ color: "#737373" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/*
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="Export_Domestic"
                className={css.formItem}
                label={<span className={css.label}>Export/Domestic</span>}
                rules={rules}
              >
                <Radio.Group block options={exportDomesticOptions} />
              </Form.Item>
            </Col>
          </Row>    
          */}    
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="source"
                className={css.formItem}
                label={<span className={css.label}>Source</span>}
                rules={rules}
              >
                <Radio.Group block options={sourceOptions} />
              </Form.Item>
            </Col>
          </Row>        

          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="Grouping"
                className={css.formItem}
                label={<span className={css.label}>Grouping</span>}
                rules={rules}
              >
                <Select
                  allowClear
                  placeholder="Grouping"
                  align="left"
                  size="large"
                  options={grouping}
                />
              </Form.Item>
            </Col>
          </Row>        
          <Row gutter={16}>
            <Col xs={6} md={24} lg={12} xl={3}>
              <Form.Item>
                <MainButton
                  size="large"
                  rootClassName={css.submitButton}
                  htmlType="submit"
                  loading={false}
                >
                  Save
                </MainButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Flex>
  </>
  );
};
