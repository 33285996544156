import dayjs from "dayjs";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getOverviewRequest,
  getSalesSummaryRequest,
  getGoodsRequest,
  getCurrentOrdersRequest,
  getSalesOrdersRequest
} from "../../api";

import { salesOrdersFormatter } from "../SalesOrders/SalesOrdersFormatter"

const initialState = {
  overview: [],
  overviewExport: null,
  salesSummary: [],
  goods: [],
  currentOrders: [],
  salesOrders: [],
  getOverviewInProgress: false,
  getOverviewError: null,
  getOverviewExportInProgress: false,
  getOverviewExportError: null,
  getSalesSummaryInProgress: false,
  getSalesSummaryError: null,
  getGoodsInProgress: false,
  getGoodsError: null,
  getSalesOrderInProgress: false,
  getSalesOrderError: null
};

export const getOverview = createAsyncThunk(
  "overview/getOverview",
  async (_, { rejectWithValue }) => {
	try {
	  const { page, pageSize, search, sort } = _;
	  const response = await getOverviewRequest({
		page,
		pageSize,
		search,
		sort,
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);

export const getOverviewExport = createAsyncThunk(
  "overview/getOverviewExport",
  async (_, { rejectWithValue }) => {
	try {
	  const { search, sort } = _;
	  const response = await getOverviewRequest({
		page: 1,
		search,
		sort,
		all: true
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);


export const getSalesSummary = createAsyncThunk(
  "overview/getSalesSummary",
  async (_, { rejectWithValue }) => {
	try {
	  const { upc, type, start, end } = _;
	  const response = await getSalesSummaryRequest({
	  	upc,
		type,  
		start,
		end
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);

export const getGoods = createAsyncThunk(
  "overview/getGoods",
  async (_, { rejectWithValue }) => {
	try {
	  const { upc, page, search, sort } = _;
	  const response = await getGoodsRequest({
		upc,
		page,
		search,
		sort,
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);

export const getCurrentOrders = createAsyncThunk(
  "overview/getCurrentOrders",
  async (_, { rejectWithValue }) => {
	try {
	  const { upc, page, search, sort } = _;
	  const response = await getCurrentOrdersRequest({
		upc,
		page,
		search,
		sort,
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);

export const getSalesOrders = createAsyncThunk(
  "overview/getSalesOrders",
  async (_, { rejectWithValue }) => {
	try {
	  const { upc, start, end, mode, page, search, sort } = _;
	  const response = await getSalesOrdersRequest({
		upc,
		start,
		end,
		mode,
		page,
		search,
		sort,
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);

export const dashboardSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
	clearData(state) {
	  state.overview = [];
	},
  },
  extraReducers: (builder) => {
	builder
	  .addCase(getOverview.pending, (state) => {
		state.getOverviewInProgress = true;
		state.getOverviewError = null;
	  })
	  .addCase(getOverview.fulfilled, (state, action) => {
	  	let { payload =[] } = action;
		let totalPages = 0;
		if(Array.isArray(payload) && payload.length > 0) {
			totalPages = payload[0].total_rows;
		}
		for(let row of payload) {
			row.key = `${row.UPC}_${row.Catalogue_Number}`;
			row.Release_Date = row.Release_Date ? dayjs(row.Release_Date).format('DD-MM-YYYY') : 'N/A';
		}  
		state.overview = {data: payload, totalPages};  
		state.getOverviewInProgress = false;
		state.getOverviewError = null;
	  })
	  .addCase(getOverview.rejected, (state, action) => {
		state.getOverviewInProgress = false;
		state.getOverviewError = action.payload;
	  })
	  
	  .addCase(getOverviewExport.pending, (state) => {
		state.getOverviewExport = null;
		state.getOverviewExportInProgress = true;
		state.getOverviewExportError = null;
		})
		.addCase(getOverviewExport.fulfilled, (state, action) => {
		let { payload =[] } = action;
		for(let row of payload) {
			row.Release_Date = row.Release_Date ? dayjs(row.Release_Date).format('DD-MM-YYYY') : 'N/A';
		}  
		state.overviewExport = payload;  
		state.getOverviewExportInProgress = false;
		state.getOverviewExportError = null;
		})
		.addCase(getOverviewExport.rejected, (state, action) => {
		state.getOverviewExportInProgress = false;
		state.getOverviewExportError = action.payload;
		})

	  
	  .addCase(getSalesSummary.pending, (state) => {
  		state.getSalesSummaryInProgress = true;
  		state.getSalesSummaryError = null;
	  })
	  .addCase(getSalesSummary.fulfilled, (state, action) => {
  		const sales = {data: action.payload, total_rows: action.payload.length};  
  		state.salesSummary = sales;
  		state.getSalesSummaryInProgress = false;
  		state.getSalesSummaryError = null;
	  })
	  .addCase(getSalesSummary.rejected, (state, action) => {
  		state.getSalesSummaryInProgress = false;
  		state.getSalesSummaryError = action.payload;
	  })
	  .addCase(getGoods.pending, (state) => {
		state.getGoodsInProgress = true;
		state.getGoodsError = null;
	  })
	  .addCase(getGoods.fulfilled, (state, action) => {
		let { payload =[] } = action;
		for(let row of payload) {
			row.Receipt_Date = row.Receipt_Date ? dayjs(row.Receipt_Date).format('DD-MM-YYYY') : 'N/A';
		}  
		const goods = {data: payload, total_rows: action.payload.length};  
		state.goods = goods;
		state.getGoodsInProgress = false;
		state.getGoodsError = null;
	  })
	  .addCase(getGoods.rejected, (state, action) => {
		state.getGoodsInProgress = false;
		state.getGoodsError = action.payload;
	  })
	  .addCase(getCurrentOrders.pending, (state) => {
		state.getCurrentOrdersInProgress = true;
		state.getCurrentOrdersError = null;
	  })
	  .addCase(getCurrentOrders.fulfilled, (state, action) => {
		const orders = {data: action.payload, total_rows: action.payload.length};  
		state.currentOrders = orders;
		state.getCurrentOrdersInProgress = false;
		state.getCurrentOrdersError = null;
	  })
	  .addCase(getCurrentOrders.rejected, (state, action) => {
		state.getCurrentOrdersInProgress = false;
		state.getCurrentOrdersError = action.payload;
	  })
	  .addCase(getSalesOrders.pending, (state) => {
		state.getSalesOrdersInProgress = true;
		state.getSalesOrdersError = null;
		})
	  .addCase(getSalesOrders.fulfilled, (state, action) => {
		const { rows, dates } = salesOrdersFormatter(action.payload);
		const sales = {data: rows, dates, total_rows: rows.length};  
		state.salesOrders = sales;
		state.getSalesOrdersInProgress = false;
		state.getSalesOrdersError = null;
	  })
	  .addCase(getSalesOrders.rejected, (state, action) => {
		state.getSalesOrdersInProgress = false;
		state.getSalesOrdersError = action.payload;
	  })
  },
});

export const { clearData } = dashboardSlice.actions;

// this is for configureStore
export default dashboardSlice.reducer;
