import { uniq, map, find, sortBy } from 'lodash';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const _salesOrdersFormatter = (data) => {
	let rows = [];
	if(Array.isArray(data) && data.length) {
		let upcs = uniq(map(data, 'UPC'));
		for(let upc of upcs) {
			let row = {upc};
			let total = 0;
			let sales = data.filter(i=>i.UPC == upc);
			for(let sale of sales) {
				row[sale.Group_Date] = sale.Quantity;
				total += sale.Quantity;
			}
			row.total = total;
			rows.push(row);
		}
	}
	return rows;
}

const sortDates = (date) => {
	const dateFormat = date.match(/^\w{3}-\d{4}$/) ? 'MMM-YYYY' : 'DD-MM-YYYY';
	return dayjs(date, dateFormat).unix();
}

export const salesOrdersFormatter = (data) => {
	let rows = [], dates = [];
	if(Array.isArray(data) && data.length) {
		let numbers = uniq(map(data, 'Catalogue_Number'));
		dates = uniq(map(data, 'Group_Date'));
		dates = sortBy(dates, sortDates);
		for(let number of numbers) {
			let row = {Catalogue_Number: number};
			let total = 0;
			for(let date of dates) {
				const entry = find(data, item=>(item.Catalogue_Number == number && item.Group_Date == date));
				const quantity = entry ? Number(entry.Quantity) : 0;
				row[date] = quantity;
				total += quantity;
			}
			row.Total = total;
			rows.push(row);
		}
	}
	return { rows, dates };
}