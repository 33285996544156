import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAccountTypesRequest,
  updateAccountTypeRequest,
  getGroupingRequest,
  getPendingCountRequest
} from "../../api";

const initialState = {
  accountTypes: [],
  grouping: [],
  pendingCount: 0,
  getAccountTypesInProgress: false,
  getAccountTypesError: null,
  accountType: null,
  getAccountTypeInProgress: false,
  getAccountTypeError: null,
  getGroupingInProgress: false,
  getGroupingError: null,
  updateAccountTypeInProgress: false,
  updateAccountTypeError: null,
};

export const getAccountTypes = createAsyncThunk(
  "accountTypes/getAccountTypes",
  async (_, { rejectWithValue }) => {
    try {
      const { page, search, sort, status } = _;
      const response = await getAccountTypesRequest({
        page,
        search,
        sort,
        status
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateAccountType = createAsyncThunk(
  "accountTypes/updateAccountType",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = _;
      const response = await updateAccountTypeRequest(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getGrouping = createAsyncThunk(
  "accountTypes/getGrouping",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getGroupingRequest();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getPendingCount = createAsyncThunk(
  "accountTypes/getPendingCount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPendingCountRequest();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const accountTypesSlice = createSlice({
  name: "accountTypes",
  initialState,
  reducers: {
    clearData(state) {
      state.accountType = null;
      state.pendingCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountTypes.pending, (state) => {
        state.getAccountTypesInProgress = true;
        state.getAccountTypesError = null;
      })
      .addCase(getAccountTypes.fulfilled, (state, action) => {
        let totalPages = 0,
        {payload} = action;
        if(Array.isArray(payload) && payload.length > 0) {
          totalPages = payload[0].total_rows;
        }  
        state.accountTypes = {data: payload, totalPages};
        state.getAccountTypesInProgress = false;
        state.getAccountTypesError = null;
      })
      .addCase(getAccountTypes.rejected, (state, action) => {
        state.getAccountTypesInProgress = false;
        state.getAccountTypesError = action.payload;
      })
      .addCase(getGrouping.pending, (state) => {
        state.getGroupingInProgress = true;
        state.getGroupingError = null;
      })
      .addCase(getGrouping.fulfilled, (state, action) => {
        state.grouping = action.payload;
        state.getGroupingInProgress = false;
        state.getGroupingError = null;
      })
      .addCase(getGrouping.rejected, (state, action) => {
        state.getGroupingInProgress = false;
        state.getGroupingError = action.payload;
      })
      .addCase(getPendingCount.pending, (state) => {
        state.pendingCount = 0;
      })
      .addCase(getPendingCount.fulfilled, (state, action) => {
        state.pendingCount = action.payload.count;
      })
      .addCase(getPendingCount.rejected, (state, action) => {
      })

      .addCase(updateAccountType.pending, (state) => {
        state.pendingCount = 0;
        state.updateAccountTypeInProgress = true;
        state.updateAccountTypeError = null;
      })
      .addCase(updateAccountType.fulfilled, (state, action) => {
        state.updateAccountTypeInProgress = false;
        state.updateAccountTypeError = null;
      })
      .addCase(updateAccountType.rejected, (state, action) => {
        state.updateAccountTypeInProgress = false;
        state.updateAccountTypeError = action.payload;
      });
  },
});

export const { clearData } = accountTypesSlice.actions;

// this is for configureStore
export default accountTypesSlice.reducer;
