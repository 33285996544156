import React, { useEffect, useState } from 'react';
import { Menu, Badge } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPendingCount } from "../../views/AccountTypes/accountTypesSlice";
import { TableOutlined, DashboardOutlined } from '@ant-design/icons';
import { checkUserRole } from '../../utils/helpers'
import css from './MainMenu.module.css';

export const MainMenu = () => {
	const dispatch = useDispatch();
	
	const currentUser = JSON.parse(localStorage.getItem("user"));
	const role = currentUser?.role;
	
	useEffect(() => {
		dispatch(
		  getPendingCount()
		);
	}, []);
	
	const { pendingCount } = useSelector((state) => state?.accountTypes) || {};

	const items = [
	  {
			key: 'stock',
			icon: <TableOutlined />,
			label: 'Stock',
			children: [
				{
				key: 'stock_sales',
				label: 'Stock and Sales',
				children: [
					{
					key: 'dashboard',
					label: <Link to='/dashboard'>Dashboard</Link>
					},
				],
				},
			],
	  },
		{
			key: 'admin',
			icon: <DashboardOutlined />,
			label: <Badge count={pendingCount}>Admin</Badge>,
			role: 'label_admin|admin',
			children: [
				{
					key: 'account-types',
					label: <Badge count={pendingCount}><Link to='/account-types'>Account Types&nbsp;&nbsp;&nbsp;</Link></Badge>,
					role: 'admin',
					},
				{
					key: 'users',
					label: <Link to='/admin/users'>User Management</Link>,
					role: 'label_admin|admin',
				},
			],
		},
	];
	
	const [current, setCurrent] = useState('dashboard');
	
	const onClick = (e) => {
  		// console.log('click', e);
		setCurrent(e.key);
	};

	const filterMenuItems = (menuItems) =>
    menuItems
      .filter((item) => checkUserRole(role, item.role)) 
      .map((item) => ({
        ...item,
        children: item.children ? filterMenuItems(item.children) : undefined,
      }));

  const filteredItems = filterMenuItems(items);

  return (
  	<Menu
			onClick={onClick}
			className={css.mainMenu}
			mode="inline"
			selectedKeys={[current]}
			defaultOpenKeys={['stock']}
			items={filteredItems}
		/>	
  )
}