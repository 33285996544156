import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Col, Flex, Form, Input, Row, Select, Table, Radio} from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getAccountTypes, clearData } from "./accountTypesSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "./HeaderCell";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { IconEdit } from "../../components/IconEdit/IconEdit";
import { accountTypeStatusOptions } from "../../constants/accountTypes";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

import css from "./AccountTypes.module.css";

export const AccountTypes = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [sortDirection, setSortDirection] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const page = new URLSearchParams(location.search).get("page") || 1;
  const search = new URLSearchParams(location.search).get("search");
  const sort = new URLSearchParams(location.search).get("sort");
  const [status, setStatus] = useState(true);

  const onQueryParamsChange = (values) => {
    const queryParams = {
      page,
      search,
      sort,
      ...values,
    };

    const queryString = buildQueryString("/account-types", queryParams);
    navigate(queryString);
  };

  const { accountTypes, getAccountTypesInProgress } =
    useSelector((state) => state?.accountTypes) || {};

  const {data: contactsList, totalPages}  = accountTypes || [];


  useEffect(() => {
    dispatch(
      getAccountTypes({
        page,
        search,
        sort: sortDirection,
        status
      }),
    );
  }, [page, search, sortDirection, status]);

  useEffect(() => {
    if (
      location.state &&
      ["/accountTypeEdited"].includes(location.state.from)
    ) {
      setShowNotification(true);
      navigate("/account-types", { state: null });
    } else {
      setShowNotification(false);
    }
  }, [location?.state]);

  const columns = [
    {
      title: "Account",
      editable: true,
      dataIndex: "Account",
      width: 250,
      sorter: (_, __, sort) =>
        setSortDirection(`Account:${sort === "ascend" ? "asc" : "desc"}`),
      align: "left",
      defaultSortOrder: 'ascend'
    },
    /*
    {
      title: "Who",
      editable: true,
      dataIndex: "Who",
      width: 250,
      sorter: (_, __, sort) =>
        setSortDirection(`Who:${sort === "ascend" ? "asc" : "desc"}`),
      align: "left",
    },
    */
    {
      title: "Store Distro",
      editable: true,
      dataIndex: "Store_Distro",
      sorter: (_, __, sort) =>
        setSortDirection(`Store_Distro:${sort === "ascend" ? "asc" : "desc"}`),
      width: 200,
      align: "left",
    },
    {
      title: "Country",
      editable: true,
      dataIndex: "Country",
      sorter: (_, __, sort) =>
        setSortDirection(`Country:${sort === "ascend" ? "asc" : "desc"}`),
      width: 200,
      align: "left",
    },
    /*
    {
      title: "Export/Domestic",
      editable: true,
      dataIndex: "Export_Domestic",
      sorter: (_, __, sort) =>
        setSortDirection(`Export_Domestic:${sort === "ascend" ? "asc" : "desc"}`),
      width: 200,
      align: "left",
    },
    */
    {
      title: "Source",
      editable: true,
      dataIndex: "source",
      sorter: (_, __, sort) =>
        setSortDirection(`source:${sort === "ascend" ? "asc" : "desc"}`),
      width: 200,
      align: "left",
    },

    {
      title: "Grouping",
      editable: true,
      dataIndex: "Grouping",
      sorter: (_, __, sort) =>
        setSortDirection(`Grouping:${sort === "ascend" ? "asc" : "desc"}`),
      width: 200,
      align: "left",
    },    
    {
      title: "Actions",
      dataIndex: "",
      fixed: "right",
      key: "x",
      width: 100,
      align: "left",
      render: (record) => {
        return (
          <Flex gap={16} align={"center"}>
            <div className={css.actionContainer}>
              <span
                className={css.actionIcon}
                onClick={() => {
                  dispatch(clearData());
                  navigate('/account-types/edit', { state: { record } });
                }}
              >
                <IconEdit />
              </span>
            </div>
          </Flex>
        );
      },
    },
  ];

  const onSearch = (value) => {
    onQueryParamsChange({ search: value, page: 1 });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    debouncedSave(value);
  };

  const handleStatusChange = (value) => {
    onQueryParamsChange({ status: value });
  };

  const saveInput = (value) => onSearch(value);

  const debouncedSave = debounce(saveInput, 500);

  return (<>
    <Notification
      message="Success"
      description={
        "Account type was edited successfully"
      }
      showNotification={showNotification}
      cancel={() => setShowNotification(false)}
    />
    <Breadcrumbs paths={[{path: `/account-types/`, breadcrumbName: `Account Types`}]} />
    <PageHeader title="Account Types" /> 
      <Flex className={css.root} vertical>
        <div className={"ibox-holder"}>
          <Flex style={{ marginBottom: "16px" }}>
            <Radio.Group value={status} onChange={(e)=>setStatus(e.target.value)}>
              <Radio.Button value={true}>Pending</Radio.Button>
              <Radio.Button value={false}>All</Radio.Button>
            </Radio.Group>
          </Flex>
          {/*

          <Form
            name="searchForm"
            style={{ marginTop: 10, marginBottom: 0 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row gutter={16} className={css.searchFormContainer}>
              <Col md={24} lg={10} xl={10}>
                <Form.Item style={{ marginBottom: 10 }} name="name">
                  <Input
                    defaultValue={search}
                    size="large"
                    placeholder="Search..."
                    style={{ color: "#737373" }}
                    prefix={<IconSearch />}
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>
              <Col md={24} lg={8} xl={6}>
                <Form.Item style={{ marginBottom: 10 }} name="status">
                  <Select
                    defaultValue={status}
                    allowClear
                    placeholder="Status"
                    align="left"
                    size="large"
                    onChange={handleStatusChange}
                    options={accountTypeStatusOptions}
                  />
                </Form.Item>
              </Col>
              <Col md={24} lg={6} xl={8} className={css.actionsWrapper}>
                <MainButton
                  size="large"
                  rootClassName={css.searchButton}
                  onClick={() => navigate("/accountTypes/create")}
                >
                  Create AccountType
                </MainButton>
              </Col>
            </Row>
          </Form>
          
          */}
          
          <Form name="tableForm" form={form} component={false}>
            <Table
              components={{
                header: {
                  row: HeaderCell,
                },
              }}
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={contactsList}
              pagination={{
                position: ["bottomRight"],
                responsive: true,
                onChange: (page) => onQueryParamsChange({ page }),
                current: page,
                total: totalPages,
                pageSize: 10,
                hideOnSinglePage: true,
                showSizeChanger: false,
              }}
              loading={getAccountTypesInProgress}
              style={{ marginTop: 10 }}
              scroll={{ x: "100%" }}
            />
          </Form>
        </div>
      </Flex>
    </>
  );
};
