import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSearchRequest,
  getFormatsRequest,
} from "../../api";

const initialState = {
	results: [],
	formats: [],
	getSearchInProgress: false,
	getSearchError: false,
	forceEntity: undefined,
	getFormatsInProgress: false,
	getFormatsError: false
};

export const getSearch = createAsyncThunk(
  "search/getSearch",
  async (_, { rejectWithValue }) => {
	try {
	  const { query } = _;
	  const response = await getSearchRequest({
		query
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);

export const setForceEntity = createAsyncThunk(
  "search/setForceEntity",
  async (_, { rejectWithValue }) => {
	try {
	  return Promise.resolve({..._});
	} catch (error) {
		return rejectWithValue(error.message);
	  }
	},
);

export const getFormats = createAsyncThunk(
  "search/getFormats",
  async (_, { rejectWithValue }) => {
	try {
	  const response = await getFormatsRequest({
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);


export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
	clearData(state) {
	  state.results = [];
	},
  },
  extraReducers: (builder) => {
	builder
	  .addCase(getSearch.pending, (state) => {
		state.getSearchInProgress = true;
		state.getSearchError = null;
	  })
	  .addCase(getSearch.fulfilled, (state, action) => {
		state.results = action.payload;
		state.getSearchInProgress = false;
		state.getSearchError = null;
	  })
	  .addCase(getSearch.rejected, (state, action) => {
		state.getSearchInProgress = false;
		state.getSearchError = action.payload;
	  })
	  .addCase(setForceEntity.pending, (state) => {
		state.forceEntity = undefined;
	  })
	  .addCase(setForceEntity.fulfilled, (state, action) => {
		state.forceEntity = action.payload;
	  })
	  .addCase(setForceEntity.rejected, (state, action) => {
		state.forceEntity = undefined;
	  })
	  .addCase(getFormats.pending, (state) => {
		state.getFormatsInProgress = true;
		state.getFormatsError = null;
	  })
	  .addCase(getFormats.fulfilled, (state, action) => {
		state.formats = action.payload;
		state.getFormatsInProgress = false;
		state.getFormatsError = null;
	  })
	  .addCase(getFormats.rejected, (state, action) => {
		state.getFormatsInProgress = false;
		state.getFormatsError = action.payload;
	  });
  },
});

export const { clearData } = searchSlice.actions;

// this is for configureStore
export default searchSlice.reducer;
