import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Tooltip, Spin } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getOverview, getOverviewExport, clearData } from "./dashboardSlice";
import { setForceEntity } from "../SearchBox/searchSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "./HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { SalesSummary } from "../SalesSummary/SalesSummary";
import { Goods } from "../GoodsIn/GoodsIn";
import { SearchBox } from "../SearchBox/SearchBox";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { CurrentOrders } from "../CurrentOrders/CurrentOrders";
import { SalesOrders } from "../SalesOrders/SalesOrders";
import { LastUpdates } from "../LastUpdates/LastUpdates";
import { EscapedCSVLink as CSVLink } from "../../components/EscapedCSVLink/EscapedCSVLink";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { valueCell } from "../../components/ValueCell/ValueCell";
import { dateCell } from "../../components/DateCell/DateCell";
import { TableWithRow as Table} from "../../components/TableWithRow/TableWithRow";
import { exportToXLSX } from "../../utils/helpers";
import { FieldSelector } from "../../components/FieldSelector/FieldSelector";

import css from "./Dashboard.module.css";

export const Dashboard = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const defaultSortColumn = "Available_Stock:desc"
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [upcs, setUpcs] = useState([]);
  const [minReleaseDate, setMinReleaseDate] = useState(new Date());
  
  const [search, setSearch] = useState({search: [], format: [], range: {start: null, end: null}});
  const [sortDirection, setSortDirection] = useState(defaultSortColumn);
  const exportButtonRef = React.useRef();
  const [exportInProgress, setExportInProgress] = useState(false);
  const [exportMode, setExportMode] = useState('');
  

  // const page = new URLSearchParams(location.search).get("page") || 1;
  //const search = new URLSearchParams(location.search).get("search");
  //const sort = new URLSearchParams(location.search).get("sort");
  /*
  const setSortDirection = (dir) => {
    console.log('ssd');
    console.log(dir);
    ssd(dir);
    
  }
  */
  
  const onQueryParamsChange = ({newPage}) => {
    setPage(page);
//     const queryParams = {
//       page,
//       sort: sortDirection
//     };
// 
//     const queryString = buildQueryString("/dashboard", queryParams);
//     navigate(queryString);
  };
    
  const { overview, getOverviewInProgress } =
    useSelector((state) => state?.dashboard) || {};

  const { overviewExport, getOverviewExportInProgress } =
    useSelector((state) => state?.dashboard) || {};

  const { salesSummary, salesOrders, goods } = useSelector((state) => state?.dashboard) || {};
  const { data: rows, totalPages } = overview || {};

  /*
  useEffect(() => {
    dispatch(
      getOverview({
        page,
        pageSize,
        search,
        sort: sortDirection,
      }),
    );
  }, []);
  */
  
  const getFullData = () => {
    setExportMode('table');
    dispatch(
      getOverviewExport({
        search,
        sort: sortDirection,
      })
    ).then((overviewExport) => {
      if (overviewExport !== null) {
        exportButtonRef.current.link.click();
        setExportMode('');
      }
    });
  };

  const getAllDataExport = () => {
    setExportInProgress(true);
    setExportMode('pdf');

    dispatch(
      getOverviewExport({
        search,
        sort: sortDirection,
      })
    )
    .then((action) => {
      const overviewData = action.payload || [];
      const salesSummaryData = salesSummary?.data || [];
      const salesOrdersData = salesOrders?.data || [];
      const goodsData = goods?.data || [];

      const sheets = [
        { name: "Overview", data: overviewData },
        { name: "Sales Summary", data: salesSummaryData },
        { name: "Sales Orders", data: salesOrdersData },
        { name: "Goods In", data: goodsData },
      ].filter(sheet => sheet.data.length > 0);

      exportToXLSX(sheets, "dashboard_data.xlsx"); 
    })
    .catch((error) => {
      console.error("Error fetching overview data for export:", error);
    })
    .finally(() => {
      setExportInProgress(false);
      setExportMode('');
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let { current: page, pageSize = 10 } = pagination;
    const { field, order } = sorter;
    const sort = (order === undefined) ?  defaultSortColumn : `${field}:${order.replace('end', '')}`;
    
    if(sort != sortDirection)
      page = 1;
      
    setPage(page);
    setPageSize(pageSize);
    setSortDirection(sort);
    
    dispatch(
      getOverview({
        page,
        pageSize, 
        search,
        sort
      })
    );
  };
  
  const forceEntity = (name, entity) => {
    dispatch(setForceEntity({
        name,
        entity
    }))
  }
  
  const columns = [
    {
      title: (
        <Tooltip title="The title of the product / release, the associated primary Artist, and UPC">
          <div>Title</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Title",
      width: 250,
      sorter: true,
      align: "left",
      showSorterTooltip: false,
      render: (_, record) => (
        <div>
          <a 
            href="#" 
            className={css.clickableElement} 
            onClick={(e) => { 
              e.preventDefault(); 
              forceEntity(record.Title, 'title'); 
            }}
          >
            <strong>{record.Title}</strong>
          </a>
          <br />
          <a 
            href="#" 
            className={css.clickableElement} 
            onClick={(e) => { 
              e.preventDefault(); 
              forceEntity(record.Artist, 'artist'); 
            }}
          >
            By: {record.Artist}
          </a>
          <br />
          <a 
            href="#" 
            className={css.clickableElement} 
            onClick={(e) => { 
              e.preventDefault(); 
              forceEntity(record.UPC, 'UPC'); 
            }}
          >
            UPC: {record.UPC}
          </a>
        </div>
      ),
    },
    // {
    //   title: (
    //     <Tooltip title="The title of the product / release">
    //       <div>Title</div>
    //     </Tooltip>
    //   ),
    //   editable: true,
    //   dataIndex: "Title",
    //   width: 250,
    //   sorter: true,
    //   showSorterTooltip: false,
    //   align: "left",
    // },
    {
      title: (
        <Tooltip title="Label Name">
          <div>Label</div>
        </Tooltip>
      ),

      editable: true,
      dataIndex: "Label",
      width: 250,
      sorter: true,
      align: "left",
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip title="Catalogue Number">
          <div>Catalogue Number</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Catalogue_Number",
      sorter: true,
      width: 200,
      showSorterTooltip: false,
      align: "left",
      render: (record) => (<a 
        href="#" 
        className={css.clickableElement} 
        onClick={(e) => { 
          e.preventDefault(); 
          forceEntity(record, 'catalog'); 
        }}
      >
        {record}
      </a>)
    },
    {
      title: (
        <Tooltip title='Physical format description (12" Record represents all 12" formats - Maxi Single/EP/LP)'>
          <div>Format</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Format",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      className: "format-cell",
    },
    // {
    //   title: (
    //     <Tooltip title="Barcode EAN13 UPC">
    //       <div>UPC</div>
    //     </Tooltip>
    //   ),
    //   editable: true,
    //   dataIndex: "UPC",
    //   sorter: true,
    //   width: 200,
    //   showSorterTooltip: false,
    //   align: "left",
    // },
    {
      title: (
        <Tooltip title="Release date to consumer of product">
          <div>Release Date</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Release_Date",
      sorter: true,
      width: 200,
      align: "left",
      showSorterTooltip: false,
      render: (record) => dateCell(record, "DD-MM-YYYY")
    },    
    {
      title: (
        <Tooltip title="Wholesale price for UK retailers (excludes discounts)">
          <div>UK PPD (£)</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "UK_PPD",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },
    {
      title: (
        <Tooltip title="Wholesale price for Irish retailers in Euros (excludes discounts).">
          <div>IE PPD (€)</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "IE_PPD",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },    
    {
      title: (
        <Tooltip title="Goods put into stock (includes returns)">
          <div>Goods In</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Goods_In",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },
    
    {
      title: (
        <Tooltip title="Stock that is ready for sale to retailers, with no pending orders or reservations.">
          <div>Av. Stock</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Available_Stock",
      sorter: true,
      width: 150,
      align: "left",
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },
    {
      title: (
        <Tooltip title="Stock that is currently not available for retail due to pending put away, held orders, or other restrictions.">
          <div>Unav. Stock</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Unavailable_Stock",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },
    {
      title: (
        <Tooltip title="Total quantity of stock held in the main warehouse.">
          <div>Total Stock</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Total_Stock",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },
    {
      title: (
        <Tooltip title="Quantity of stock held on consignment in third-party warehouses (e.g., HMV/Fopp).">
          <div>Cons. Stock</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Consignment_Stock",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },    
    
    {
      title: (
        <Tooltip title="Total current presales (excluding Consignment / FOC)">
          <div>New Release Held</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "New_Release_Held",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },
    {
      title: (
        <Tooltip title="Total current orders (Inc. Consignment / FOC)">
          <div>All orders</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "All_Orders",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },
    
    {
      title: (
        <Tooltip title="Total shipments to date (excluding Consignment / FOC)">
          <div>Sold Units to date</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Sold_Units_To_Date",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },    
    {
      title: (
        <Tooltip title="Total quantity of shipments sent to consignment accounts, such as HMV and Fopp.">
          <div>Cons. Ship to date</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "Consignment_Ship_To_Date",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },    
    {
      title: (
        <Tooltip title="Total shipments to Free-of-Charge (FOC) or promotional accounts.">
          <div>FOC Ship to date</div>
        </Tooltip>
      ),
      editable: true,
      dataIndex: "FOC_Ship_To_Date",
      sorter: true,
      width: 150,
      align: "left",
      showSorterTooltip: false,
      render: (record) => valueCell(record)
    },   


 

    /*
    {
      title: "Actions",
      dataIndex: "",
      fixed: "right",
      key: "x",
      width: 100,
      align: "left",
      render: (record) => {
        return (
          <Flex gap={16} align={"center"}>
            <div className={css.actionContainer}>
              <span
                className={css.actionIcon}
                onClick={() => {
                  navigate(`/channels/edit/${record.id}`);
                }}
              >
              </span>
            </div>
          </Flex>
        );
      },
    },*/
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.reduce((acc, col) => ({ ...acc, [col.dataIndex]: true }), {})
  );
  const filteredColumns = columns.filter(col => visibleColumns[col.dataIndex]);

  const [frozenColumn, setFrozenColumn] = useState(null);

  const handleFreezeColumn = (columnKey, event) => {
    if (event.shiftKey) {
      setFrozenColumn(prev => (prev === columnKey ? null : columnKey));
    }
  };

  const handleSort = (column, event) => {
    if (event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      return; 
    }
  };

  const [isShiftPressed, setIsShiftPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(true);
      }
    };
    
    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const updatedColumns = filteredColumns.map((col, index) => {
    const isFrozen = frozenColumn && filteredColumns.findIndex(c => c.dataIndex === frozenColumn) >= index;
    const isLastFrozen = isFrozen && (index === filteredColumns.findIndex(c => c.dataIndex === frozenColumn));
    
    return {
      ...col,
      fixed: isFrozen ? "left" : undefined,
      sorter: !isShiftPressed, 
      className: isLastFrozen ? "last-frozen-column" : "",
      onHeaderCell: () => ({
        onClick: (event) => {
          if (event.shiftKey) {
            handleFreezeColumn(col.dataIndex, event);
          } else {
            handleSort(col, event);
          }
        },
      }),
    };
  });

  const onSelect = (keys, rows) => {
    setUpcs(keys);
    const releaseDates = rows.map(({Release_Date})=>{
      const [day, month, year] = Release_Date.split('-').map(Number);
      return new Date(year, month - 1, day);
    });
    setMinReleaseDate(Math.min(...releaseDates));
  }
  
  const onApplySearch = (search) => {
    setSearch(search);
    setPage(1);
    setUpcs([]);
    
    dispatch(
      getOverview({
        page: 1,
        pageSize,
        search,
        sort: sortDirection,
      }),
    );

  }
  
  const onResetSearch = (search) => {
    setSearch(search);
    setPage(1);
    setUpcs([]);
    
    dispatch(
      clearData({
      })
    );
  
  }

  
  const upcsSelected = Array.isArray(upcs) && upcs.length > 0;

  return (<>
    <Breadcrumbs paths={[{path: `/dashboard/`, breadcrumbName: `Dashboard`}]} />
    <PageHeader title="Stock and Sales Dashboard">
      {(exportMode == 'pdf' && (exportInProgress || getOverviewExportInProgress)) && <Spin size="small" />}
      <a onClick={getAllDataExport} disabled={exportInProgress} title="Export to XLS">
        <IconDownload />
      </a>

    </PageHeader>
    <Flex className={css.root} vertical>
      <div className={"ibox-holder"}>
        <SearchBox onApply={onApplySearch} onReset={onResetSearch} />
      </div>
      
      <LastUpdates />
      
      <div className={"ibox-holder"}>
        <SectionHeader title="Search Table" titleClassName="mainSectionHeader">
          <Flex align="center" justify="flex-end" gap="10px">
            <FieldSelector
              columns={columns}
              selectedColumns={visibleColumns}
              onApply={setVisibleColumns}
            />
            <CSVLink data={overviewExport || []} linkRef={exportButtonRef} filename="overview.csv" enclosingCharacter={`"`} />
            { (exportMode == 'table' && getOverviewExportInProgress ) && <Spin size="small" />}
            <a onClick={getFullData} disabled={getOverviewExportInProgress}><IconDownload /></a>
          </Flex>
        </SectionHeader>
        <Form name="tableForm" form={form} component={false}>
          <Table
            components={{
              header: {
                row: HeaderCell,
              },
            }}
            columns={updatedColumns}
            rowKey={(record) => record.key}
            dataSource={rows}
            pagination={{
              position: ["bottomRight"],
              responsive: true,
              current: page,
              total: totalPages,
              pageSize: pageSize,
              hideOnSinglePage: true,
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100', '1000']
            }}
            loading={getOverviewInProgress}
            style={{ marginTop: 10 }}
            scroll={{ x: "100%" }}
            onChange={handleTableChange}
            rowSelection={{
              selectedRowKeys: upcs,
              type: 'checkbox',
              onChange: onSelect
            }}
            tableLayout="fixed"
            locale={{ emptyText: 'Enter your search parameters to start' }} 
          />
        </Form>
      </div>
      {upcsSelected && <SalesSummary upc={upcs} />}
      {upcsSelected && <div className={"ibox-holder"}><SalesOrders upc={upcs} minReleaseDate={minReleaseDate} /></div>}
    </Flex>
  </>
  );
};
